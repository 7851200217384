/* html 기본 적용 */

* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  background-color: #fdfbf7;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-zoom: none;
  -webkit-touch-callout: none;
}

/* media query 적용 */

@media (min-width: 576px) {
  .wrapper {
    width: 390px;
    margin: 0 auto;
  }
}

/* fonts */

/* for password in Input */
@font-face {
  font-family: "Pretendard";
  src: url("./fonts/PretendardVariable.ttf") format("woff2");
  /* font-weight: 100 - 900 */
}

@font-face {
  font-family: "NanoomSquare";
  src: url("./fonts/NanumSquareR.ttf") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "NanoomSquare";
  src: url("./fonts/NanumSquareEB.ttf") format("woff2");
  font-weight: 800;
}

@font-face {
  font-family: "Galmuri";
  src: url("./fonts/Galmuri11.woff2") format("woff2");
}

@font-face {
  font-family: "Galmuri";
  src: url("./fonts/Galmuri11-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Galmuri";
  src: url("./fonts/Galmuri11-Condensed.woff2") format("woff2");
  font-stretch: condensed;
}

@font-face {
  font-family: "PFStardust";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/PFStardust.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PFStardust-old";
  src: url("./fonts/PF스타더스트\ 1.4\(Old\ Ver\).otf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
